import { MsalProvider } from '@azure/msal-react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';

import { msalConfig } from './config/auth';
import { store } from './redux/store';
import App from './App';

import './style.css';
import { Provider } from 'react-redux';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as any);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <StrictMode>
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </Provider>
    </StrictMode>
);

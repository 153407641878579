import styled from 'styled-components';

export const Page = styled.div`
    nav {
        h1,
        button,
        button .material-icons-round {
            color: var(--neutral-color-soft-base) !important ;
        }
    }

    .box {
        margin-top: 56px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }

    .form {
        border: 1px solid var(--brand-color-primary-light);
        box-sizing: border-box;
        border-radius: 4px;

        .select {
            border: none;
            border-left: 1px solid var(--brand-color-primary-light);
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            color: var(--neutral-color-hard-base);

            option {
                color: var(--neutral-color-hard-base);
            }
        }

        .input-group {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            width: 100%;

            span {
                position: absolute;
                font-size: var(--font-size-xs);
                padding: calc(var(--padding-xs) + var(--border-width-xs));
                pointer-events: none;
            }

            input {
                display: block;
                background-color: var(--neutral-color-soft-base);
                border-radius: var(--border-radius-xs);
                padding: var(--padding-xs);
                font-family: var(--font-family-base);
                font-style: normal;
                font-weight: var(--font-weight-regular);
                font-size: var(--font-size-xs);
                color: var(--neutral-color-hard-base);
                text-indent: calc(var(--padding-xs) + var(--font-size-xs));

                &:hover:not(:disabled) {
                    border-color: var(--brand-color-primary-light);
                    background-color: var(--neutral-color-soft-light);
                }

                &:focus,
                &:focus-visible {
                    border: none;
                    outline: none;
                }
            }
        }

        .input {
            border: none;
        }

        .button {
            padding: var(--padding-xs) 4.75rem; // não há 4.75rem no design tokens
        }
    }

    .box-result {
        margin-top: -20px;
        padding: 50px 0 32px 0;
        border: none;

        h1 {
            font-family: var(--font-family-base);
            font-style: normal;
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-xs);
            line-height: var(--font-size-xs);
            color: var(--neutral-color-hard-base);
            margin: 0;
            padding: 0;
            text-align: left;
        }

        p {
            font-family: var(--font-family-base);
            font-style: normal;
            font-weight: var(--font-weight-regular);
            font-size: var(--font-size-xs);
            line-height: var(--font-size-xs);
            color: var(--neutral-color-hard-base);
            text-align: left;
        }
    }

    .box-tab {
        display: flex;
        flex-direction: column;

        .tab-header {
            margin: 12px 0 24px 0;

            ul {
                margin: 0;
                padding: 0;
                display: flex;

                li {
                    list-style: none;

                    button {
                        border: none;
                        font-family: 'Dosis';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        color: #a1a1a1;
                        padding: 26px 24px;
                    }

                    .inative {
                        background: #f0f0f0;
                        border-radius: 0;
                        border-bottom: 1px solid #f0f0f0 !important;
                    }

                    .active {
                        color: #411e5a;
                        background: #ffffff;
                        border-radius: 0;
                        border-bottom: 1px solid #411e5a !important;
                        border: none;
                    }
                }
            }
        }

        .tab-content {
            table {
                width: 100%;
                margin-bottom: 54px;

                thead {
                    th {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                        padding: 12px;
                        font-family: 'Roboto', sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 16px;
                        color: #000000;
                        text-align: left;
                    }
                }

                tbody {
                    tr {
                        td {
                            font-family: 'Roboto', sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 16px;
                            color: #000000;
                            text-align: left;
                            padding: 12px;
                        }
                    }
                }
            }
        }
    }

    .box-feature {
        margin: 160px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom-left-radius: 18px;
        border-top-left-radius: 56px;
        border-bottom-right-radius: 56px;

        h1 {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: var(--neutral-color-soft-base);
            margin-bottom: 16px;
        }

        button {
            background: linear-gradient(180deg, #ee5534 0%, #faaf35 100%);
            border-radius: 40px;
        }
    }

    .badgeAtivo {
        background-color: #4CAF50;
        color: white;
        padding: 4px 8px;
        text-align: center;
        border-radius: 5px;
      }

      .badgeInativo {
        background-color: #FFB200;
        color: white;
        padding: 4px 8px;
        text-align: center;
        border-radius: 5px;
      }
      .badgeBloqueado {
        background-color: #F0462D;
        color: white;
        padding: 4px 8px;
        text-align: center;
        border-radius: 5px;
      }
`;

export const Logo = styled.div`
    padding-top: 140px;
    text-align: center;
`;

export const Label = styled.p`
  color: #000000;
  margin: 0;
`;

export const Button = styled.a`
  text-decoration: none;
`;


import React from 'react';

import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated
} from '@azure/msal-react';

import routes from './config/routes';
import DefaultTemplate from './templates/default';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                {routes.map((route, key) => {
                    const { component: Component } = route;

                    if (route.security) {
                        return (
                            <Route
                                key={key}
                                path={route.path}
                                element={
                                    <DefaultTemplate>
                                        <Component {...route} />
                                    </DefaultTemplate>
                                }
                            />
                        );
                    }

                    return (
                        <Route
                            key={key}
                            path={route.path}
                            element={<Component {...route} />}
                        />
                    );
                })}
            </Routes>
        </BrowserRouter>
    );
};

export default App;

import LoginPage from '../pages/authentication/login';
import HomePage from '../pages/home';

type Route = {
    path: string;
    component: any;
    security?: boolean;
};

const routes: Route[] = [
    {
        path: '/',
        component: HomePage,
        security: true
    },
    {
        path: '/login',
        component: LoginPage
    }
];

export default routes;

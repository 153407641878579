const styles = {
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 1200,
        lg: 1440,
        xl: 1920
    },
    containerMaxWidths: {
        sm: 1440,
        md: 1440,
        lg: 1440,
        xl: 1440
    },
    columns: 12,
    gutterWidth: 30
};

export default styles;

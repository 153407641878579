import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { loginRequest } from '../../../config/auth';

import {
    Col,
    Container,
    Content,
    Form,
    FormGroup,
    Linx,
    Logo,
    Row,
    Title
} from './styles';
import { LxButton } from 'linx-design-system';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = React.useState('null');
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (isAuthenticated) {
            navigate('/', { replace: true });
        }
    }, [isAuthenticated]);

    const handleLogin = () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance
            .acquireTokenSilent(request)
            .then(response => {
                setAccessToken(response.accessToken);

                navigate('/', { replace: true });
            })
            .catch(e => {
                instance.acquireTokenPopup(request).then(response => {
                    setAccessToken(response.accessToken);
                });
            });
    };

    return (
        <Container>
            <Content>
                <Row>
                    <Col
                        size={3}
                        style={{
                            backgroundColor: 'var(--brand-color-primary-base)'
                        }}
                    >
                        <Logo>
                            <h5>Linx</h5>
                            <h1>Atendimento Equals</h1>
                        </Logo>
                    </Col>
                    <Col size={7}>
                        <Linx
                            src={require('../../../assets/images/logo.png')}
                            alt='Logo da Linx'
                        />
                        <Form>
                            <FormGroup>
                                <div>
                                    <Title>
                                        Logue com a sua conta de email da{' '}
                                        <span>Linx</span>
                                    </Title>
                                </div>
                                <div>
                                    <LxButton
                                        kind='primary'
                                        onClick={handleLogin}
                                    >
                                        Conectar
                                    </LxButton>
                                </div>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Content>
        </Container>
    );
};

export default LoginPage;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AxiosError } from 'axios';

export const fetchFunction = createAsyncThunk(
    'client/fetchFunction',
    async ({ document, product }: { document: string; product: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `https://api.linx.com.br/raiox/v1/api-connect?document=${document}&subscription-key=edd7339d8605408f83f58cd1fdc8b400`
            );

            return response.data;
        } catch (err: unknown) {
            return rejectWithValue(err);
        }
    }
);

export const clientSlice = createSlice({
    name: 'client',
    initialState: {
        loading: false,
        status: 'idle',
        customer: {
            name: '',
            corporateName: '',
            document: '',
            status: '',
            type: '',
            product: '',
            productName: '',
            payment: '',
            support: '',
            clientId: ''

        },
        acquirers: [],
        operators: [],
        stores: []
    },
    reducers: {
        setClient: (state, action) => {
            state.customer = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchFunction.pending, (state, action) => {
                state.status = 'pending';
                state.loading = true;
                state.customer = {
                    name: '',
                    corporateName: '',
                    document: '',
                    status: '',
                    type: '',
                    product: '',
                    productName: '',
                    payment: '',
                    support: '',
                    clientId: ''
                };

                state.acquirers = [];
                state.operators = [];
                state.stores = [];
            })
            .addCase(fetchFunction.fulfilled, (state, action) => {
                state.customer = {
                    ...state.customer,
                    ...action.payload.customer
                };
                state.acquirers = action.payload.acquirers;
                state.operators = action.payload.operators;
                state.stores = action.payload.stores;
                state.status = 'fulfilled';
                state.loading = false;
            })
            .addCase(fetchFunction.rejected, (state, action) => {
                state.customer = {
                    name: '',
                    corporateName: '',
                    document: '',
                    status: '',
                    type: '',
                    product: '',
                    productName: '',
                    payment: '',
                    support: '',
                    clientId: ''
                };

                state.acquirers = [];
                state.operators = [];
                state.stores = [];
                state.status = (action.payload as AxiosError).response?.status === 404 ? 'notFound' : 'rejected';
                state.loading = false;
            })
            .addDefaultCase((state, action) => {
                state.status = 'idle';
                state.loading = false;
                state.customer = {
                    name: '',
                    corporateName: '',
                    document: '',
                    status: '',
                    type: '',
                    product: '',
                    productName: '',
                    payment: '',
                    support: '',
                    clientId: ''
                };

                state.acquirers = [];
                state.operators = [];
                state.stores = [];
            });
    }
});

export const { setClient } = clientSlice.actions;

export default clientSlice.reducer;

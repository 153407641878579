import {
    AuthenticatedTemplate,
    useIsAuthenticated,
    useMsal
} from '@azure/msal-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    const handleLogout = (instance: any) => {
        instance.logoutRedirect().catch((e: any) => {
            console.error(e);
        });
    };

    React.useEffect(() => {
        if (!isAuthenticated) navigate('/login', { replace: true });
    }, []);

    return (
        <React.Fragment>
            <h5>
                {isAuthenticated ? `Olá, ${accounts[0].name}` : 'Olá mundo'}
            </h5>
            <AuthenticatedTemplate>
                <button onClick={() => handleLogout(instance)}>Sair</button>
            </AuthenticatedTemplate>
        </React.Fragment>
    );
};

export default HomePage;

import React from "react";
import { IPopover } from "./types";

import { Container } from "./styles";

const Popover = ({ children, show }: IPopover) => {
  return <>{show && <Container>{children}</Container>}</>;
};

export default Popover;

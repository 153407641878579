import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #fff;

  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12),
    0px 20px 40px -8px rgba(0, 0, 0, 0.06);

  padding: 16px 8px;

  width: 158px;
  height: 90px;

  position: absolute;
  bottom: 0;
  top: 45px;
  right: 95px;

  animation: fadeIn 0.9s both;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const msalConfig = {
    auth: {
        clientId: 'b42b0831-f973-4953-9ef3-59d21053dabe',
        authority:
            'https://login.microsoftonline.com/c58f8565-7ab4-440f-84f2-dd75f3745a69/',
        redirectUri: '/',
        validateAuthority: true,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    },
    system: {
        navigateFrameWait: 0
    }
};

export const loginRequest = {
    scopes: ['user.read', 'user.readbasic.all']
};

export const graphConfig = {
    graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me'
};

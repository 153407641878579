import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div``;

export const Row = styled.div`
    display: flex;
`;

export const Col = styled.div`
    flex: ${(props: { size: number }) => '0.' + props.size};
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const Logo = styled.div`
    h1 {
        font-family: var(--font-family-highlight);
        font-style: normal;
        font-weight: var(--font-weight-light);
        font-size: var(--font-size-xl);
        color: var(--neutral-color-soft-base);
        margin: 0;
    }

    h5 {
        font-family: var(--font-family-base);
        font-style: normal;
        font-weight: var(--font-weigth-regular);
        font-size: var(--font-size-lg);
        color: var(--feedback-color-warning-base);
        margin: 0;
    }
`;

export const Linx = styled.img`
    width: 21rem;
`;

export const Form = styled.form`
    width: 21rem;
    margin-top: var(--margin-md);

    button {
        text-transform: uppercase;
    }
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h1`
    margin: 0;
    padding: 0;
    font-family: var(--font-family-highlight);
    font-style: normal;
    font-weight: var(--font-weigth-regular);
    font-size: var(--font-size-md);
    color: var(--neutral-color-hard-base);

    span {
        font-weight: var(--font-weight-bold);
    }
`;

import React from 'react';
import {
    LxButton,
    LxIcon,
    LxInput,
    LxNavbar,
    LxNavbarItem,
    LxContainer,
    LxRow,
    LxCol,
    LxBox,
    LxSelect,
    LxSelectOption,
    LxLink
} from 'linx-design-system';
import Popover from "../../components/Popover";
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import { Page, Logo, Label, Button } from "./styles";
import styles from '../../config/styles';
import { fetchFunction } from '../../redux/clients';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { mask } from '../../helpers/cnpj';

interface TemplateProps {
    children: React.ReactNode;
}

const DefaultTemplate: React.FC<TemplateProps> = ({ children }) => {
    const [result, setResult] = React.useState<boolean>(false);
    const [document, setDocument] = React.useState<string>('');
    const [product, setProduct] = React.useState<string>('');
    const [tabActive, setTabActive] = React.useState<number>(0);
    const [showSelect, setShowSelect] = React.useState<boolean>(false);
    const [showPopove, setShowPopover] = React.useState<boolean>(false);
    const client = useAppSelector(state => state.client);
    const dispatch = useAppDispatch();

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const imageEqualsLite = require('../../assets/images/equals-lite.png')
    const imageEqualsBusiness = require('../../assets/images/equals-business.png')
    const imageEqualsControle = require('../../assets/images/equals-controle.png')
    const imageRaioX = require('../../assets/images/logo-raiox.png')

    const handleLogout = (instance: any) => {
        instance.logoutRedirect().catch((e: any) => {
            console.error(e);
        });
    };

    const showSelectDocs = (show: boolean) => {
        debugger;
        setShowSelect(show);
    };

    const handleShowPopover = () => {
        setShowPopover(!showPopove);
    };

    React.useEffect(() => {
        if (!isAuthenticated) navigate('/login', { replace: true });

        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('product'))
            setProduct(urlParams.get('product') || '');
    }, []);

    const handleSimulate = () => {
        setResult(true);
        dispatch(
            fetchFunction({
                document: document.replace(/[^0-9]/g, ''),
                product
            })
        );
    };

    const handleChangeDocument = (value: any) => {
        setDocument(mask(value));
    };

    const validate = () => { };

    const parseImage = (product: any) => {
        switch (product) {
            case 'B':
                return imageEqualsBusiness;
            case 'L':
                return imageEqualsLite;
            case 'R':
                return imageRaioX;
            case 'C':
                return imageEqualsControle;
            default:
                return 'X';
        }
    }

    const getProductUrl = (product: any) => {
        switch (product) {
            case 'L':
                return 'https://share.linx.com.br/x/A54LEQ'
            case 'R':
                return 'https://share.linx.com.br/x/ZskbEQ'
            default:
                return '#';
        }
    }

    return (
        <Page>
            <LxNavbar
                style={{
                    background: 'var(--brand-color-primary-base)'
                }}
                title='Atendimento Equals'
                logo={require('../../assets/images/logo.svg').default}
            >
                <LxNavbarItem>
                    <LxButton onClick={() => handleLogout(instance)}>
                        <LxIcon icon='exit_to_app' />
                        Sair
                    </LxButton>
                </LxNavbarItem>
                <LxNavbarItem>
                    <LxButton onClick={() => handleShowPopover()}>
                        <LxIcon icon="description" />
                        Documentações
                        <LxIcon icon="arrow_drop_down" source="material" />
                    </LxButton>
                </LxNavbarItem>
            </LxNavbar>

            <LxContainer styles={styles}>
                <LxRow>
                    <LxCol>
                        <Logo>
                            <img
                                src={
                                    require('../../assets/images/equals.svg')
                                        .default
                                }
                                alt=''
                            />
                        </Logo>
                    </LxCol>
                </LxRow>
                <LxRow noGutters={true}>
                    <LxCol>
                        <form
                            onSubmit={(e: any) => {
                                e.preventDefault();

                                if (document.length) {
                                    handleSimulate();
                                } else if (!document.length) {
                                    alert('O campo CNPJ é obrigatório.');
                                }
                            }}
                        >
                            <LxBox className='box form'>
                                <div className='input-group'>
                                    <span className='material-icons-round '>
                                        search
                                    </span>
                                    <input
                                        max={18}
                                        maxLength={18}
                                        type='text'
                                        className='input'
                                        value={document}
                                        placeholder='Informe o CNPJ'
                                        onChange={e =>
                                            handleChangeDocument(e.target.value)
                                        }
                                    />
                                </div>
                                <LxButton type='submit' className='button'>
                                    Pesquisar
                                </LxButton>
                            </LxBox>
                        </form>
                    </LxCol>
                </LxRow>
            </LxContainer>
            <Popover show={showPopove}>
                <Button href="https://share.linx.com.br/x/A54LEQ" target={"_blank"}>
                    <Label>Equals Lite</Label>
                </Button>
                <Button href="https://share.linx.com.br/x/ZskbEQ" target={"_blank"}>
                    <Label>Raio-X</Label>
                </Button>
            </Popover>

            {client.status === 'fulfilled' ? (
                <>

                    {(client.customer.document !== '' && mask(client.customer.document) != document) ?
                        <LxBox>
                            <LxRow>
                                <p style={{ color: '#C0000C' }}>Este CNPJ pertence ao grupo do CNPJ {mask(client.customer.document)}.</p>
                            </LxRow>
                        </LxBox> :
                        null}
                    <LxBox bg='var(--neutral-color-soft-light)' className='box-result' id='lxBoxSituacao'>
                        <LxContainer styles={styles}>
                            <LxRow>
                                <LxCol>
                                    { (client.customer.product !== 'X') ?
                                    <img src={parseImage(client.customer.product)} height={65} width={215} alt={client.customer.product} />
                                    : null }
                                </LxCol>
                                <LxCol>
                                    <h1>Situação</h1>
                                    <p>
                                        {client.customer.status}
                                    </p>
                                </LxCol>
                                <LxCol>
                                    <h1>Mensalidade</h1>
                                    <p>
                                        {client.customer.payment}
                                    </p>
                                </LxCol>
                                <LxCol>
                                    <h1>Suporte</h1>
                                    <p>
                                        {client.customer.support}
                                    </p>


                                </LxCol>
                            </LxRow>
                        </LxContainer>
                        <LxContainer styles={styles}>
                            <LxRow>
                                <LxCol>
                                    <h1>Nome Fantasia</h1>
                                    <p>
                                        {client.customer.name.length
                                            ? client.customer.name
                                            : '-'}
                                    </p>
                                </LxCol>
                                <LxCol>
                                    <h1>Razão Social</h1>
                                    <p>
                                        {client.customer.corporateName.length
                                            ? client.customer.corporateName
                                            : '-'}
                                    </p>
                                </LxCol>
                                <LxCol>
                                    <h1>CNPJ</h1>
                                    <p>
                                        {client.customer.document.length
                                            ? mask(client.customer.document)
                                            : '-'}
                                    </p>
                                </LxCol>
                                <LxCol>
                                    <h1>Código Equals</h1>
                                    <p>
                                        {client.customer.clientId}
                                    </p>
                                </LxCol>
                            </LxRow>
                        </LxContainer>
                    </LxBox>
                </>
            ) : client.status === 'pending' ? (
                <>
                    <LxBox
                        bg='var(--neutral-color-soft-light)'
                        className='box-result'
                    >
                        <LxContainer styles={styles}>
                            <LxRow>
                                <LxCol>
                                    <h1 style={{ textAlign: 'center' }}>
                                        Aguarde, carregando as informações.
                                    </h1>
                                </LxCol>
                            </LxRow>
                        </LxContainer>
                    </LxBox>
                </>
            ) : client.status === 'notFound' ? (
                <>
                    <LxBox
                        bg='var(--neutral-color-soft-light)'
                        className='box-result'
                    >
                        <LxContainer styles={styles}>
                            <LxRow>
                                <LxCol>
                                    <h1 style={{ textAlign: 'center' }}>
                                        CNPJ informado não localizado na base de clientes Equals.
                                    </h1>
                                </LxCol>
                            </LxRow>
                        </LxContainer>
                    </LxBox>
                </>

            ) : client.status === 'rejected' ? (
                <>
                    <LxBox
                        bg='var(--neutral-color-soft-light)'
                        className='box-result'
                    >
                        <LxContainer styles={styles}>
                            <LxRow>
                                <LxCol>
                                    <h1 style={{ textAlign: 'center' }}>
                                        Houve um erro ao consultar o CNPJ informado. Se persistir entre em <a href="mailto:arqtec@linx.com.br" target="_blank" rel="noreferrer">contato</a> conosco.
                                    </h1>
                                </LxCol>
                            </LxRow>
                        </LxContainer>
                    </LxBox>
                </>
            ) : null}

            {client.status === 'fulfilled' ? (
                <LxBox>
                    <LxContainer styles={styles}>
                        <LxRow>
                            <LxCol sm={12} md={12}>
                                <div className='box-tab'>
                                    <div className='tab-header'>
                                        <ul>
                                            <li key={"adquirente"}>
                                                <LxButton
                                                    onClick={() =>
                                                        setTabActive(0)
                                                    }
                                                    className={
                                                        tabActive !== 0
                                                            ? 'active'
                                                            : 'inative'
                                                    }
                                                >
                                                    Adquirente
                                                </LxButton>
                                            </li>
                                            <li key={"operador"}>
                                                <LxButton
                                                    onClick={() =>
                                                        setTabActive(1)
                                                    }
                                                    className={
                                                        tabActive !== 1
                                                            ? 'active'
                                                            : 'inative'
                                                    }
                                                >
                                                    Operador
                                                </LxButton>
                                            </li>
                                            <li key={"lojas"}>
                                                <LxButton
                                                    onClick={() =>
                                                        setTabActive(2)
                                                    }
                                                    className={
                                                        tabActive !== 2
                                                            ? 'active'
                                                            : 'inative'
                                                    }
                                                >
                                                    Lojas
                                                </LxButton>
                                            </li>
                                        </ul>
                                    </div>
                                    {tabActive === 0 ? (
                                        <div className='tab-content'>
                                            {client.acquirers.length ? (
                                                client.acquirers.map(
                                                    (acquirer: {
                                                        name: string;
                                                        icon: string;
                                                        accreditations: any[];
                                                    }) => {
                                                        return acquirer.accreditations.length ? (
                                                            <>
                                                                <table>
                                                                    <thead >
                                                                        <tr>
                                                                            <th                                                                           >
                                                                                <span>{acquirer.name}</span>
                                                                            </th>
                                                                            <th
                                                                                style={{
                                                                                    width: '33%'
                                                                                }}
                                                                            >
                                                                                <img src={acquirer.icon} style={{ marginBottom: 12, maxHeight: 60, width: 90, objectFit: 'contain' }} />
                                                                            </th>
                                                                            {acquirer.accreditations[0].estabelecimento
                                                                                .nrCpfCnpj != null ?
                                                                                <th>
                                                                                </th> : null

                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <th
                                                                                style={{
                                                                                    width: '33%'
                                                                                }}
                                                                            >
                                                                                Filiação
                                                                            </th>
                                                                            {acquirer.accreditations[0].estabelecimento
                                                                                .nrCpfCnpj != null ?
                                                                                <th>
                                                                                    Loja
                                                                                </th> : null

                                                                            }
                                                                            <th
                                                                                style={{
                                                                                    width: '33%'
                                                                                }}
                                                                                colSpan={
                                                                                    1
                                                                                }
                                                                            >
                                                                                Status
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            acquirer.accreditations.map(
                                                                                (credential: {
                                                                                    dsStatusDetalhe: string;
                                                                                    estabelecimento: any;
                                                                                    dsNumeroFiliacao: string;

                                                                                }) => (
                                                                                    <tr key={credential.dsNumeroFiliacao}>

                                                                                        <td>{credential.dsNumeroFiliacao}</td>
                                                                                        {
                                                                                            credential.estabelecimento.nrCpfCnpj != null ? (<td>
                                                                                                {mask(
                                                                                                    credential
                                                                                                        .estabelecimento
                                                                                                        .nrCpfCnpj
                                                                                                )}
                                                                                            </td>) : null
                                                                                        }
                                                                                        <td>
                                                                                            {
                                                                                                credential.dsStatusDetalhe
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                    </tbody>
                                                                </table>
                                                            </>
                                                        ) : null;
                                                    }
                                                )
                                            ) : (
                                                <p
                                                    style={{
                                                        margin: 12
                                                    }}
                                                >
                                                    Nenhum adquirente
                                                    cadastrado.
                                                </p>
                                            )}
                                        </div>
                                    ) : <></>}
                                    {tabActive === 1 ? (<div className='tab-content'>
                                        <div>
                                            <>
                                                {client.operators.length ? (
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        width: '25%'
                                                                    }}
                                                                >
                                                                    Nome
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        width: '25%'
                                                                    }}
                                                                    colSpan={1}
                                                                >
                                                                    E-mail
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {client.operators.map(
                                                                (operator: {
                                                                    name: string;
                                                                    email: string;
                                                                    status: boolean;
                                                                    block: boolean;
                                                                }) => (
                                                                    <tr key={operator.email}>
                                                                        <td>
                                                                            {
                                                                                operator.name
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                operator.email
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {operator.status
                                                                                ? <span className="badgeAtivo">Ativo</span>
                                                                                : <span className="badgeInativo">Inativo</span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {operator.block
                                                                                ?
                                                                                <span className="badgeBloqueado">Bloqueado</span>
                                                                                : null}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textAlign:
                                                                'center'
                                                        }}
                                                    >
                                                        Nenhum operador
                                                        cadastrado.
                                                    </p>
                                                )}
                                            </>
                                        </div>
                                    </div>) : <></>}
                                    {tabActive === 2 ? (<div className='tab-content'>
                                        <div>
                                            <>
                                                {client.stores.length ? (
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        width: '25%'
                                                                    }}
                                                                >
                                                                    CNPJ
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        width: '25%'
                                                                    }}
                                                                    colSpan={1}
                                                                >
                                                                    Razão Social
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        width: '25%'
                                                                    }}
                                                                    colSpan={1}
                                                                >
                                                                    Nome Fantasia
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        width: '25%'
                                                                    }}
                                                                    colSpan={1}
                                                                >
                                                                    Status
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {client.stores.map(
                                                                (store: {
                                                                    name: string;
                                                                    corporateName: string;
                                                                    document: string;
                                                                    status: string;
                                                                }) => (
                                                                    <tr key={store.document}>
                                                                        <td>
                                                                            {store.document != null ? mask(store.document) : null}
                                                                        </td>
                                                                        <td>
                                                                            {store.name}
                                                                        </td>
                                                                        <td>
                                                                            {store.corporateName}
                                                                        </td>
                                                                        <td>
                                                                            {store.status}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p
                                                        style={{
                                                            textAlign:
                                                                'center'
                                                        }}
                                                    >
                                                        Nenhum operador
                                                        cadastrado.
                                                    </p>
                                                )}
                                            </>
                                        </div>
                                    </div>) : <></>}
                                </div>
                            </LxCol>
                        </LxRow>
                    </LxContainer>
                </LxBox>
            ) : null}
            {client.status === 'fulfilled' && (client.customer.product === 'R' || client.customer.product === 'L') ? (
                <LxContainer styles={styles}>
                    <LxRow>
                        <LxCol>
                            <LxBox bg='#411e5a' p='24px' className='box box-feature'>
                                <h1>
                                    Leia a documentação completa do {client.customer.productName} no Share
                                </h1>
                                <LxButton
                                    className='button-custom'
                                    onClick={() => window.open(getProductUrl(client.customer.product))}
                                >
                                    Acessar documentação
                                </LxButton>
                            </LxBox>
                        </LxCol>
                    </LxRow>


                </LxContainer>) : null}
        </Page>
    );
};

export default DefaultTemplate;
